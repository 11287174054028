import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';

const UmiiButton = ({
  className, btnText, variant, onClick, ...props
}) => (
  <Button {...props} variant={variant} className={className} onClick={onClick}>{btnText}</Button>
);


UmiiButton.propTypes = {
  className: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

UmiiButton.defaultProps = {
  className: 'loginBtn',
  variant: 'contained',
  onClick: () => { },
};
export { UmiiButton };
