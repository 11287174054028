import { actionTypes, Common } from '../../../utils';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';
import { checkDateFormat } from '../../../utils/common';

const { STUDENT_ACTIONS } = actionTypes;
const { SET_RECORD, CLEAR_STUDENTS, SET_FILTER_STUDENTS } = STUDENT_ACTIONS;

const { Dashboard, Account, University } = config.REST_API;

export const setRecord = (key, value) => ({
  type: key,
  payload: value
});
export const clearStudents = () => ({
  type: CLEAR_STUDENTS
});

export const isInclude = (item, searchVal) =>
  item.toLowerCase().replace(/ /g, '').includes(searchVal);
export const getFiltered =
  (searchVal, records, isUnconfirmed) => (dispatch) => {
    if (searchVal === '') {
      if (isUnconfirmed) {
        const searchedRec = records.filter(
          (item) => item.IsEmailConfirmed === false
        );
        dispatch(setRecord(SET_FILTER_STUDENTS, searchedRec));
      } else {
        dispatch(setRecord(SET_FILTER_STUDENTS, records));
      }
    } else {
      const searchedRec = records.filter((item) => {
        if (isUnconfirmed) {
          return (
            item.IsEmailConfirmed === false &&
            (isInclude(item.Email, searchVal) ||
              isInclude(item.OtherEmail, searchVal) ||
              isInclude(item.StudentName, searchVal))
          );
        }
        return (
          isInclude(item.Email, searchVal) ||
          isInclude(item.OtherEmail, searchVal) ||
          isInclude(item.StudentName, searchVal)
        );
      });

      dispatch(setRecord(SET_FILTER_STUDENTS, searchedRec));
    }
  };
export const onGetStudents =
  ({ uniName }) =>
  (dispatch) => {
    dispatch(
      setRecord(SET_RECORD, {
        uniData: {
          id: '',
          name: '',
          loading: true
        }
      })
    );
    axiosBasic
      .get(Dashboard.GetUniversityStudents + uniName)
      .then((response) => {
        const {
          success,
          data: { students, university, ActiveInActiveMarkerDate },
          message
        } = response.data;
        if (success) {
          const uniData = {
            id: university.Id,
            name: university.Name,
            loading: false
          };
          const rec = {
            students,
            uniData,
            ActiveInActiveMarkerDate: checkDateFormat(ActiveInActiveMarkerDate)
          };
          dispatch(setRecord(SET_FILTER_STUDENTS, students));
          dispatch(setRecord(SET_RECORD, rec));
        } else {
          Common.showErrorMessage(message);
        }
      })
      .catch((error) => {
        const uniData = {
          id: '',
          name: '',
          loading: false
        };
        const students = [];
        const rec = { students, uniData, ActiveInActiveMarkerDate: '' };
        dispatch(setRecord(SET_FILTER_STUDENTS, students));
        dispatch(setRecord(SET_RECORD, rec));
        console.log(error);
      });
  };
export const onConfirmUser =
  (userId, closeDialoxBox, getStudents) => (_dispatch) => {
    axiosBasic
      .put(Account.ConfirmUser + userId)
      .then((res) => {
        const { success, message } = res.data;
        if (success) {
          getStudents();
          Common.showSuccessMessage(message);
          closeDialoxBox();
        } else {
          Common.showErrorMessage(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const onSignifierCreate = (model) => (_dispatch) => {
  axiosBasic({
    url: University.AddSignifier,
    method: 'post',
    data: model
  })
    .then((response) => {
      const { success, message } = response.data;
      if (success) {
        Common.showSuccessMessage('Signifier added successfully');
      } else {
        Common.showSuccessMessage('Error occured while adding signifier');
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const onSignifierUpdate = (model) => (dispatch) => {
  axiosBasic({
    url: University.UpdateSignifier,
    method: 'post',
    data: model
  })
    .then((response) => {
      const { success, message } = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
