import { actionTypes, Common } from '../../../utils';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';

const { APPLICANT_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES } = APPLICANT_ACTIONS;

const { University, Applicant } = config.REST_API;

export const setUniversities = (value) => ({
  type: SET_UNIVERSITIES,
  payload: value
});

export const onGetUniverities = () => (dispatch) => {
  axiosBasic
    .get(University.University)
    .then((response) => {
      const { success, data, message } = response.data;

      if (success) {
        dispatch(setUniversities(data));
      } else {
        Common.showErrorMessage(message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const onUploadApplicants = (formData) => () =>
  axiosBasic({
    url: Applicant.AddApplicant,
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((response) => {
      const { success, message } = response.data;
      if (success) {
        Common.showSuccessMessage('Applicants added successfully!');
      } else {
        Common.showErrorMessage(message);
      }
      return {
        success: success
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        success: false
      };
    });

export const onGetAppicationCount = (universityId) =>
  axiosBasic
    .get(`${Applicant.GetCount}/${universityId}`)
    .then((response) => {
      const { success, message } = response.data;

      if (!success) {
        Common.showErrorMessage(message);
      }
      return { data: response.data };
    })
    .catch((error) => {
      console.log(error);
      Common.showErrorMessage('Unable to fetch applicants count');
    });
