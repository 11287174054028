import PropTypes from "prop-types";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";

const Domains = ({
  DomainsList,
  onAddDomainClick,
  onDeleteDomainClick,
  onEditDomainClick,
}) => (
  <div className="date">
    <div>
      <p>Custom Domains</p>
      {DomainsList.map((d) => (
        <div
          key={d.Id}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>{`@${d.Name}`}</p>
          <span>
            <EditIcon
              className="yellowEmail"
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => onEditDomainClick(d)}
            />
            <CancelIcon
              style={{ cursor: "pointer", color: "#FB3904" }}
              onClick={() => onDeleteDomainClick(d)}
            />
          </span>
        </div>
      ))}
      <a onClick={onAddDomainClick}>{"Add Domain"}</a>
    </div>
  </div>
);

Domains.propTypes = {
  text: PropTypes.string,
  onAddDomainClick: PropTypes.func,
  onDeleteDomainClick: PropTypes.func,
  onEditDomainClick: PropTypes.func,
};

Domains.defaultProps = {
  text: "Loading ...",
  onAddDomainClick: () => {},
  onDeleteDomainClick: () => {},
  onEditDomainClick: () => {},
};
export { Domains };
