/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Totalizer, UmiiButton } from '../../common';

import * as applicantActions from './applicantActions';
import { Common } from '../../../utils';

const Applicants = ({
  applicants,
  applicantActions: { onGetUniverities, onUploadApplicants }
}) => {
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedFile, setselectedFile] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileKey, setFileKey] = useState('');
  const [applicantCount, setApplicantCount] = useState(null);

  useEffect(() => onGetUniverities(), []);

  const handleUniversityChange = (selectedUniversity) => {
    setSelectedUniversity(selectedUniversity);
    if (selectedUniversity.Id !== -1) {
      applicantActions
        .onGetAppicationCount(selectedUniversity.Id)
        .then((response) => {
          if (response.data.success) {
            setApplicantCount(response.data.data);
          }
        });
    }
  };

  const onFileChangeHandler = (event) => {
    setselectedFile(event.target.files[0]);
  };

  const onUploadFile = () => {
    if (!selectedUniversity || selectedUniversity.Id === -1) {
      Common.showErrorMessage('Please select a university first');
      return;
    }
    if (!selectedFile) {
      Common.showErrorMessage('Please select a file first');
      return;
    }

    let bodyFormData = new FormData();
    bodyFormData.append('universityId', selectedUniversity.Id);
    bodyFormData.append('file', selectedFile);
    setIsUploadingFile(true);
    onUploadApplicants(bodyFormData).then((response) => {
      setIsUploadingFile(false);
      if (response.success) {
        resetForm();
      }
    });
  };

  const resetForm = () => {
    const fKey = Math.random().toString(36); //NOSONAR
    setFileKey(fKey);
    setselectedFile(null);
  };

  return (
    <div className="mainContent">
      <div className="dashView">
        <div className="uniView">
          <div className="select">
            <Select
              getOptionLabel={(option) => `${option.Name}`}
              getOptionValue={(option) => `${option.Id}`}
              value={selectedUniversity}
              onChange={handleUniversityChange}
              options={applicants.universities}
            />
          </div>
          <input
            key={fileKey || ''}
            type="file"
            onChange={onFileChangeHandler}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />{' '}
          <br />
          {isUploadingFile ? (
            <Loader styles={{ float: 'right', marginTop: 15 }} />
          ) : (
            <UmiiButton
              disabled={!selectedFile}
              variant="contained"
              className="uploadBtn"
              btnText="Upload"
              style={{ float: 'right' }}
              onClick={onUploadFile}
            />
          )}
          {selectedUniversity && selectedUniversity.Id !== -1 && (
            <div style={{ textAlign: 'center', marginTop: '70px' }}>
              <Totalizer
                title={`Total Applicants For ${selectedUniversity.Name}`}
                counter={applicantCount}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    applicants: state.applicants
  }),
  (dispatch) => ({
    applicantActions: bindActionCreators(applicantActions, dispatch)
  })
)(Applicants);
