import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from '@material-ui/core/Tooltip';

const rowItem = (data, i, onClick) => {
  const res = Object.values(data);
  return (
    <TableRow key={`tr-${i}`}>
      {res.map((item, index) => {
        return (
          <React.Fragment>
            {typeof item === "string" ? <Tooltip title={item}>
              <TableCell onClick={() => onClick(data)} key={`tc-${index}`}>
                {typeof item === "string" ? item.slice(0, 25) : item}
              </TableCell>
            </Tooltip> :
              <TableCell onClick={() => onClick(data)} key={`tc-${index}`}>
                {typeof item === "string" ? item.slice(0, 25) : item}
              </TableCell>
            }
          </React.Fragment>
        );


      })}
    </TableRow>
  );
};

export { rowItem };
