import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes as AppRoutes
} from 'react-router-dom';
import { Login, Dashboard, StudentsDashboard } from '../components';
import { NotFoundPage, Main } from '../components/common';
import { RouteNames } from './routeNames';
import { WebStorage, WebStorageNames } from '../utils';
import Applicants from '../components/user/applicant/applicants';
import ConfirmUserPage from '../components/common/ConfirmUserPage';
import Societies from '../components/user/societies/Societies';

const Routes = ({ isLoggedIn }) => {
  const getLocalUserInfo = () => {
    return WebStorage.getLocalStore(WebStorageNames.UserInfo);
  };

  const authenticate = (SuccessComponent, FailComponentName) => {
    const loggedUser = getLocalUserInfo();
    if (loggedUser || isLoggedIn) {
      return (
        <Main>
          <SuccessComponent />
        </Main>
      );
    }
    return <Navigate to={FailComponentName} />;
  };

  const notAuthenticate = (SuccessComponentName, FailComponent) => {
    const loggedUser = getLocalUserInfo();
    return loggedUser || isLoggedIn ? (
      <Navigate to={SuccessComponentName} />
    ) : (
      <FailComponent />
    );
  };

  return (
    <Router>
      <AppRoutes>
        <Route exact path={RouteNames.ConfirmUser} element={ConfirmUserPage} />
        <Route
          exact
          path="/"
          element={notAuthenticate(RouteNames.Dashboard, Login)}
        />
        <Route
          exact
          path={RouteNames.Login}
          element={notAuthenticate(RouteNames.Dashboard, Login)}
        />
        <Route
          exact
          path={RouteNames.Dashboard}
          element={authenticate(Dashboard, '/')}
        />

        <Route
          exact
          path={RouteNames.Applicants}
          element={authenticate(Applicants, '/')}
        />
        <Route
          exact
          path={RouteNames.Societies}
          element={authenticate(Societies, '/')}
        />
        <Route
          exact
          path={RouteNames.StudentDetails}
          element={authenticate(StudentsDashboard, '/')}
        />
        <Route exact path="*" element={<NotFoundPage />} />
      </AppRoutes>
    </Router>
  );
};

const mapStateToProps = ({ userAccount }) => {
  const { isLoggedIn } = userAccount;
  return {
    isLoggedIn
  };
};

export default connect(mapStateToProps, {})(Routes);
