export const messages = {
  validation: {
    invalidEmail: "Invalid email address",
    minimumPassword: "Must be at least 6 characters",
    requiredField: "Required field"
  },
  Exception: {
    networkError: "Network Error",
    sessionExpired: "Session Expired"
  }
};
