export const actionTypes = {
  ACCOUNT_ACTIONS: {
    LOGIN_FORM_CHANGED: 'LOGIN_FORM_CHANGED',
    LOGIN_FORM_CLEAR_ERROR: 'LOGIN_FORM_CLEAR_ERROR',
    LOG_OUT: 'LOG_OUT'
  },
  DASHBOARD_ACTIONS: {
    SET_UNIVERSITIES: 'SET_UNIVERSITIES',
    SET_STUDENTS: 'SET_STUDENTS',
    CLEAR_STUDENTS: 'CLEAR_STUDENTS',
    SET_HEADER_TITLE: 'SET_HEADER_TITLE',
    CLEAR_HEADER_TITLE: 'CLEAR_HEADER_TITLE'
  },
  STUDENT_ACTIONS: {
    SET_RECORD: 'SET_RECORD',
    CLEAR_STUDENTS: 'CLEAR_STUDENTS',
    SET_FILTER_STUDENTS: 'SET_FILTER_STUDENTS'
  },
  APPLICANT_ACTIONS: {
    SET_UNIVERSITIES: 'GET_UNIVERSITIES'
  },
  SOCIETY_ACTIONS: {
    SET_UNIVERSITIES: 'GET_UNIVERSITIES_SOCIETY'
  }
};

export const DateFormatLanguage = 'en-US';
// eslint-disable-next-line
export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
export const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
export const validUniEmailRegex =
  /(seventechnology.co.uk$|.ac.uk$|wearenova.co.uk$)/;
export const DomainRegex =
  /^(([^<>()[\]\\.,;:\s@"]*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WebStorageNames = {
  UserInfo: 'userInfo',
  AuthInfo: 'authInfo',
  UniversityId: 'universityId',
  CustomSignifier: 'CustomSignifier',
  IsUniversityLogo: 'IsUniversityLogo',
  UniversityLogo: 'UniversityLogo'
};

export const dashboardHeader = [
  {
    id: 'uniName',
    prop: 'UniversityName',
    name: 'Uni Name',
    numeric: true
  },
  {
    id: 'users',
    prop: 'TotalStudents',
    name: 'Users',
    numeric: true
  },
  {
    id: 'uniEmail',
    prop: 'TotalUniApproved',
    name: 'Uni Email',
    numeric: true
  },
  {
    id: 'confirmed',
    prop: 'TotalEmailConfirmed',
    name: 'Confirmed',
    numeric: true
  },
  {
    id: 'UniversityId',
    prop: 'UniversityId',
    name: 'Edit',
    numeric: true
  }
];

export const StudentHeader = [
  {
    id: 'studentname',
    prop: 'StudentName',
    name: 'Student Name'
  },
  {
    id: 'email',
    prop: 'Email',
    name: 'Uni Email'
  },
  {
    id: 'otherEmail',
    prop: 'OtherEmail',
    name: 'Other Email'
  },
  {
    id: 'activeInactive',
    prop: 'status',
    name: 'Status'
  },
  {
    id: 'lastActiveDate',
    prop: 'LastActiveDate',
    name: 'Last Active'
  }
];
export const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};
