import PropTypes from 'prop-types';
import React from 'react';
import Link from '@material-ui/core/Link';

const Umiihref = ({ href, className, btnText }) => (

  <Link href={href} className={className}>{btnText}</Link>
);


Umiihref.propTypes = {
  className: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  href: PropTypes.string,
};

Umiihref.defaultProps = {
  className: 'forgetPassLink',
  href: '#',
};
export { Umiihref };
