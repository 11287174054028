/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, UmiiButton } from '../../common';

import * as SocietiesActions from './SocietiesActions';
import { Common } from '../../../utils';

const Societies = ({
  societies,
  SocietiesActions: { onGetUniverities, onUploadSocieties }
}) => {
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileKey, setFileKey] = useState('');

  useEffect(() => {
    onGetUniverities();
  }, []);

  const handleUniversityChange = (selectedUniversity) => {
    setSelectedUniversity(selectedUniversity);
  };

  const onFileChangeHandler = (event) => {
    const file = event.target.files[0];
    if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setSelectedFile(file);
    } else {
      Common.showErrorMessage('Invalid file type, please select .xlsx file');
    }
  };

  const onUploadFile = () => {
    if (!selectedUniversity || selectedUniversity.Id === -1) {
      Common.showErrorMessage('Please select a university first');
      return;
    }
    if (!selectedFile) {
      Common.showErrorMessage('Please select a file first');
      return;
    }
    let bodyFormData = new FormData();
    bodyFormData.append('uniId', selectedUniversity.Id);
    bodyFormData.append('file', selectedFile);
    setIsUploadingFile(true);
    onUploadSocieties(bodyFormData).then((response) => {
      setIsUploadingFile(false);
      if (response.success) {
        resetForm();
      }
    });
  };

  const resetForm = () => {
    const fKey = Math.random().toString(36); //NOSONAR
    setFileKey(fKey);
    setSelectedFile(null);
  };

  return (
    <div className="mainContent">
      <div className="dashView">
        <div className="uniView">
          <div className="select">
            <Select
              getOptionLabel={(option) => `${option.Name}`}
              getOptionValue={(option) => `${option.Id}`}
              value={selectedUniversity}
              onChange={handleUniversityChange}
              options={societies.universities}
            />
          </div>
          <input
            key={fileKey || ''}
            type="file"
            onChange={onFileChangeHandler}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          {isUploadingFile ? (
            <Loader
              styles={{ float: 'right', marginTop: 0, marginRight: 25 }}
            />
          ) : (
            <UmiiButton
              disabled={!selectedFile}
              variant="contained"
              className="clubsUploadBtn"
              btnText="Upload"
              onClick={onUploadFile}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => {
    return { societies: state.societies };
  },
  (dispatch) => ({
    SocietiesActions: bindActionCreators(SocietiesActions, dispatch)
  })
)(Societies);
