import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';
import { Common } from '../../../utils';
import CloseIcon from '@material-ui/icons/Close';
import * as dashboardActions from '../../user/dashboard/dashboardActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const { University } = config.REST_API;

const EnableDisableUni = ({ onClose, ...props }) => {
  const [universityInfo, setUniversityInfo] = useState({
    Name: '',
    isSsoRequired: false,
    Disabled: false,
    IsAvailable: false
  });

  const [validateForm, setValidateForm] = useState({
    UniNameInValid: false,
    UniversityLength: false
  });

  useEffect(() => {
    if (universityInfo.Name.length >= 255) {
      setValidateForm((prev) => ({ ...prev, UniversityLength: true }));
    } else {
      setValidateForm((prev) => ({ ...prev, UniversityLength: false }));
    }
  }, [universityInfo]);

  useEffect(() => {
    axiosBasic({
      url: University.University + '/' + props.universityId,
      method: 'GET'
    })
      .then((res) => {
        const response = res.data.data;
        setUniversityInfo({
          Name: response.Name,
          isSsoRequired: response.IsSsoRequired,
          Disabled: response.Disabled,
          IsAvailable: response.IsAvailable
        });
      })
      .catch(() => {
        Common.showErrorMessage('Some thing went wrong.');
      });
  }, []);

  const handleChange = (e) => {
    e.persist();
    setUniversityInfo((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }));
  };
  const handleSubmit = () => {
    axiosBasic({
      method: 'PUT',
      url: University.University,
      data: {
        Id: props.universityId,
        Disabled: universityInfo.Disabled,
        IsSsoRequired: universityInfo.isSsoRequired,
        Name: universityInfo.Name,
        IsAvailable: universityInfo.IsAvailable
      }
    })
      .then(() => {
        props.dashboardActions.onGetUniverities();
        Common.showSuccessMessage('University updated successfully!');
      })
      .catch((error) => console.log(error));
    onClose();
  };
  return (
    <div className="EditContainer">
      <div className="formContainer">
        <div className="closeBtn">
          <CloseIcon fontSize="large" onClick={onClose} />
        </div>
        <h2>Edit University</h2>
        <form>
          <TextField
            onChange={handleChange}
            name={'Name'}
            className="formTextField"
            value={universityInfo.Name}
            label="University Name"
            fullWidth
            required
            InputProps={{
              readOnly: true,
              style: { color: 'lightgray' }
            }}
          />
          {validateForm.UniversityLength && (
            <span className="errorMessage">
              Name must be less than 255 characters
            </span>
          )}
          <FormControlLabel
            onChange={(e) => handleChange(e)}
            value={universityInfo.isSsoRequired}
            name="isSsoRequired"
            className="formCheckbox"
            control={
              <Checkbox
                style={{
                  color: 'white'
                }}
              />
            }
            label="IsSsoRequired"
            checked={universityInfo.isSsoRequired}
            classes={{ label: 'checkBoxLabel' }}
          />
          <FormControlLabel
            onChange={(e) => handleChange(e)}
            value={universityInfo.Disabled}
            name="Disabled"
            className="formCheckbox"
            control={
              <Checkbox
                style={{
                  color: 'white'
                }}
              />
            }
            label="Disable University"
            checked={universityInfo.Disabled}
            classes={{ label: 'checkBoxLabel' }}
          />
          <FormControlLabel
            onChange={(e) => handleChange(e)}
            value={universityInfo.IsAvailable}
            name="IsAvailable"
            className="formCheckbox"
            control={
              <Checkbox
                style={{
                  color: 'white'
                }}
              />
            }
            label="Available"
            checked={universityInfo.IsAvailable}
            classes={{ label: 'checkBoxLabel' }}
          />
          <div className="divFormButton">
            <Button
              className="formButton"
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    dashboard: state.dashboard
  }),
  (dispatch) => ({
    dashboardActions: bindActionCreators(dashboardActions, dispatch)
  })
)(EnableDisableUni);
