import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UmiiButton, UmiiTextField, Umiihref, UmiiLabel } from '../../common';
import { messages } from '../../../utils';

const { validation } = messages;
const LoginForm = ({ handleSubmitBtn }) => {
  const onSubmitBtnClick = (values) => {
    handleSubmitBtn(values);
  };

  return (
    <div className="login">
      <form>
        <h2>Welcome back</h2>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => onSubmitBtnClick(values)}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(validation.requiredField),
            email: Yup.string()
              .email(validation.invalidEmail)
              .required(validation.requiredField)
          })}
          render={({ values, handleSubmit, setFieldValue, errors }) => (
            <div>
              <div className="row">
                <div className="col-sm">
                  <UmiiTextField
                    id="standard-name"
                    label="Email"
                    className="loginTextfield"
                    value={values.email}
                    onValueChange={(event) =>
                      setFieldValue('email', event.target.value)
                    }
                    margin="normal"
                  />
                  <UmiiLabel error>{errors.email}</UmiiLabel>
                </div>
              </div>
              <div className="row">
                <div className="col-sm mt-4">
                  <UmiiTextField
                    id="standard-password-input"
                    label="Password"
                    className="loginTextfield"
                    fieldType="password"
                    margin="normal"
                    value={values.password}
                    onValueChange={(event) =>
                      setFieldValue('password', event.target.value)
                    }
                  />
                  <UmiiLabel error>{errors.password}</UmiiLabel>
                </div>
              </div>

              <div className="row">
                <div className="col-sm text-left">
                  <Umiihref
                    href="#"
                    className="forgetPassLink"
                    btnText="Forgot Password"
                  />
                </div>
                <div className="col-sm text-right">
                  <UmiiButton
                    variant="contained"
                    className="loginBtn"
                    btnText="Login"
                    onClick={() => handleSubmit(values)}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </form>
    </div>
  );
};

export default LoginForm;
