/* eslint-disable comma-dangle */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { GridView, Totalizer, Loader } from '../../common';
import * as dashboardActions from './dashboardActions';
import { RouteNames } from '../../../router/routeNames';
import {
  dashboardHeader,
  countTotal,
  WebStorage,
  WebStorageNames
} from '../../../utils';
import ProfileSignifierDialog from '../../common/dialogBox/ProfileSignifierDialog';
import AreaOfStudyDialog from '../../common/dialogBox/AreaOfStudyDialog';
import RestaurentDialogBox from '../../common/dialogBox/RestaurentDialogBox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { Dialog } from '@material-ui/core';
import EnableDisableUni from '../../common/forms/EnableDisableUni';
import 'react-dragswitch/dist/index.css';

const Dashboard = ({
  dashboard: { universities },
  dashboardActions: { onGetUniverities }
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [studyPlace, setStudyPlace] = useState(false);
  const [openRestaurent, setOpenRestaurent] = useState(false);
  const [disableModel, setDisableModel] = useState(false);
  const [singleUniversity, setSingleUniversity] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    onGetUniverities();
  }, []);

  const onRowItemClick = (item) => {
    WebStorage.setLocalStore(WebStorageNames.UniversityId, item.UniversityId);
    WebStorage.setLocalStore(
      WebStorageNames.CustomSignifier,
      item.IsCustomSignifier
    );
    WebStorage.setLocalStore(
      WebStorageNames.IsUniversityLogo,
      item.IsUniversityLogo
    );
    if (item.UniversityLogo) {
      const splittedUrl = item.UniversityLogo.split('?');
      WebStorage.setLocalStore(WebStorageNames.UniversityLogo, splittedUrl[0]);
    } else {
      WebStorage.removeLocalStore(WebStorageNames.UniversityLogo);
    }
    navigate(
      {
        pathname: RouteNames.StudentDetails
      },
      {
        state: {
          uniData: item
        }
      }
    );
  };

  const closeCampusDialog = () => {
    setOpenDialog(false);
    setStudyPlace(false);
    setOpenRestaurent(false);
  };

  const closeDisableModel = () => {
    setDisableModel(false);
  };

  return (
    <div className="mainContent">
      <ProfileSignifierDialog
        open={openDialog}
        handleClose={closeCampusDialog}
      />

      <AreaOfStudyDialog open={studyPlace} handleClose={closeCampusDialog} />

      <RestaurentDialogBox
        open={openRestaurent}
        handleClose={closeCampusDialog}
      />

      <div className="dashView">
        <div className="gridWrapper">
          <div className="metrics">
            <Totalizer
              title="Total Universities:"
              counter={universities.length}
            />
            <Totalizer
              title="Total User:"
              counter={countTotal('TotalStudents', universities)}
            />
            <Totalizer
              title="Uni Email:"
              counter={countTotal('TotalUniApproved', universities)}
            />
            <Totalizer
              title="Confirmed"
              counter={countTotal('TotalEmailConfirmed', universities)}
            />
          </div>
          <div className="table uniView">
            {universities.length > 0 ? (
              <GridView
                dataList={universities}
                headers={dashboardHeader}
                onRowItemClick={(item) => onRowItemClick(item)}
                renderRowItem={(item, i) => (
                  <TableRow
                    className={item.Disabled ? 'Disabled' : ''}
                    key={`rowItem-${i}`}
                    onClick={() => {
                      if (item.Disabled) return;
                      onRowItemClick(item);
                    }}
                  >
                    {typeof item.UniversityName === 'string' ? (
                      <TableCell
                        onClick={() => onRowItemClick(item.UniversityName)}
                        key={`rowItem-${i}`}
                      >
                        <Tooltip title={item.UniversityName}>
                          <span>{item.UniversityName}</span>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      item.UniversityName
                    )}
                    <TableCell>{item.TotalStudents}</TableCell>
                    <TableCell>{item.TotalUniApproved}</TableCell>
                    <TableCell>{item.TotalEmailConfirmed}</TableCell>
                    <TableCell>
                      <EditIcon
                        className={item.Disabled ? 'Enable' : ''}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDisableModel(true);
                          setSingleUniversity(item.UniversityId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              />
            ) : (
              <Loader
                styles={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItem: 'center',
                  marginTop: 15,
                  marginBottom: 15
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog open={disableModel} onClose={closeDisableModel}>
        <EnableDisableUni
          onClose={closeDisableModel}
          universityId={singleUniversity}
        />
      </Dialog>
    </div>
  );
};

export default connect(
  (state) => ({
    dashboard: state.dashboard
  }),
  (dispatch) => ({
    dashboardActions: bindActionCreators(dashboardActions, dispatch)
  })
)(Dashboard);
