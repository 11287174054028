import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CampusList from './CampusList';

export default function RestaurentDialogBox({open, handleClose}) {
  return (
    <div>
  
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogBoxCls adminDialog"
      >
        <DialogTitle id="alert-dialog-title">Favorite Restaurant</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         <textarea placeholder="Please enter campus" className="textField" />
          </DialogContentText>
        </DialogContent>
        <DialogActions  className="dialogButtons">
          
          <Button 
            onClick={handleClose} 
            color="primary" 
            autoFocus 
            className="confirmBtn"
            >
            Add
          </Button>
        </DialogActions>
{/* 
        <div className="gridWrapper">
        <ul className="campusGrid gridHeading">         
                    <li>Campus name </li>
                    <li>Action</li>
                </ul>
            <ul className="campusGrid">         
                    <li>Campus name </li>
                    <li className="gridIcons"><EditIcon className="editResult"/><DeleteIcon /></li>
                </ul>
                <ul className="campusGrid">         
                    <li>Campus name </li>
                    <li className="gridIcons"><EditIcon className="editResult"/><DeleteIcon /></li>
                </ul>
                <ul className="campusGrid">         
                    <li>Campus name </li>
                    <li className="gridIcons"><EditIcon className="editResult"/><DeleteIcon /></li>
                </ul>  
        </div> */}
       
      </Dialog>
    </div>
  );
}
