

import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import combineReducers from '../reducers/combineReducer';

const AppStore = createStore(
  combineReducers,
  {},
  applyMiddleware(ReduxThunk),
);

export { AppStore };
