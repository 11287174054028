import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as studentAction from '../studentActions';
import * as domainsActions from '../../../domains/domainsActios';
import { Domains } from '../../../domains/domains';
import moment from 'moment';
import {
  DomainRegex,
  WebStorage,
  WebStorageNames,
  Common
} from '../../../../utils';
import {
  clearHeaderTitle,
  setHeaderTitle
} from '../../dashboard/dashboardActions';
import {
  UmiiSearchField,
  Totalizer,
  DialogBox,
  DomainDialogBox,
  Loader
} from '../../../common';
import ProfileSignifierDialog from '../../../common/dialogBox/ProfileSignifierDialog';
import UploadLogo from './UploadLogo';
import { axiosBasic } from '../../../../api/axios';
import { config } from '../../../../api/endPoints';
import { ToggleSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';
import StudentGridView from './StudentGridView';
import DownloadCsv from './DownloadCsv';

const DomainPatt = new RegExp(DomainRegex);
const { University } = config.REST_API;

const StudentsDashboard = ({
  students,
  studentActions,
  domainActions,
  setTitle,
  clearTitle,
  students: { filterList, university, ActiveInActiveMarkerDate }
}) => {
  const [searchText, setSearchText] = useState('');
  const [unConfirmed, setUnConfirmed] = useState(false);
  const [openAddDomainForm, setOpenAddDomainForm] = useState(false);
  const [newDomainName, setNewDomainName] = useState('');
  const [errorTextInvalidDomain, setErrorTextInvalidDomain] = useState('');
  const [uniDomains, setUniDomains] = useState([]);
  const [openDeleteDomainModal, setOpenDeleteDomainModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isProfileSignifier, setIsProfileSignifier] = useState('');
  const [signifierDialog, setSignifierDialog] = useState(false);
  const [signifiersList, setSignifiersList] = useState([]);
  const [isDeleteSignifier, setIsDeleteSignifier] = useState(false);
  const [selectedSignifier, setSelectedSignifier] = useState(null);
  const [isUniversityLogo, setIsUniversityLogo] = useState(false);
  const [universityLogo, setUniversityLogo] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate('/dashboard');
    } else {
      const isCustomSignifier = WebStorage.getLocalStore(
        WebStorageNames.CustomSignifier
      );
      const uniLogoExists = WebStorage.getLocalStore(
        WebStorageNames.IsUniversityLogo
      );
      const uniLogo = '';
      setIsProfileSignifier(isCustomSignifier);
      setIsUniversityLogo(uniLogoExists);
      setUniversityLogo(uniLogo);
      getStudents();
      return () => {
        clearTitle();
        studentActions.clearStudents();
      };
    }
  }, []);

  useEffect(() => {
    studentActions.getFiltered(
      searchText.toLowerCase().replace(/ /g, ''),
      students.studentList,
      unConfirmed
    );
  }, [searchText, unConfirmed]);

  const getDomains = ({ universityId }) => {
    domainActions.onLoadDomain({ universityId: universityId }).then((res) => {
      const { data } = res;
      setUniDomains(data.data);
    });
  };

  const getStudents = () => {
    const { uniData } = location.state;
    getDomains({ universityId: uniData.UniversityId });
    setTitle(uniData.UniversityName);
    studentActions.onGetStudents({ uniName: uniData.UniversityName });
  };

  const getCounters = (list) => {
    let uniEmail = 0;
    let confirmed = 0;
    let activeUsers = 0;
    list.forEach((element) => {
      if (element.IsEmailConfirmed) confirmed += 1;
      if (element.IsUniApproved) uniEmail += 1;
      if (isActive(element.LastActiveDate, ActiveInActiveMarkerDate))
        activeUsers++;
    });
    return {
      unconfirmed: list.length - confirmed,
      totalUsers: list.length,
      uniEmail,
      activeUsers
    };
  };

  const searchStudents = (value, isUnconfirmed) => {
    setSearchText(value);
    setUnConfirmed(isUnconfirmed);
  };

  const closeDomainForm = () => {
    setOpenAddDomainForm(false);
    setNewDomainName('');
    setErrorTextInvalidDomain('');
    setOpenDeleteDomainModal(false);
    setSelectedDomain(null);
    setIsUpdate(false);
    setIsDisabled(false);
  };

  const onUniDaminChange = (value) => {
    setNewDomainName(value);
    setErrorTextInvalidDomain('');
  };

  const onConfirmCreateDomain = () => {
    const { uniData } = location.state;
    let newDomainNameForTest = newDomainName;
    if (newDomainNameForTest.startsWith('.')) {
      //remove that dot for validation
      newDomainNameForTest = newDomainName.substring(1, newDomainName.length);
    }
    if (DomainPatt.test(`@${newDomainNameForTest}`)) {
      setIsDisabled(true);
      domainActions.onCreateDomain(
        {
          domainName: `${newDomainName}`,
          universityId: uniData.UniversityId
        },
        (onSuccess) => {
          if (onSuccess) {
            getDomains({ universityId: uniData.UniversityId });
            closeDomainForm();
          } else {
            this.setState({
              isDisabled: false
            });
          }
        }
      );
    } else {
      setErrorTextInvalidDomain('invalid domain name');
    }
  };

  const onConfirmDeleteDomain = () => {
    const { uniData } = location.state;
    if (selectedDomain) {
      domainActions.onDeleteDomain(selectedDomain, (onSuccess) => {
        if (onSuccess) {
          getDomains({ universityId: uniData.UniversityId });
        }
      });
      closeDomainForm();
    }
  };

  const profileSignifier = (e) => {
    setIsProfileSignifier(e);
    onTurnOnOffSignifier(e);
  };

  const onUniversityLogoChange = (e) => {
    setIsUniversityLogo(e);
    onTurnOnOffUniversityLogo(e);
  };

  const closeCampusDialog = () => {
    setSignifierDialog(false);
  };

  const onCreateButtonPressed = (title, type, dropOptions) => {
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    const model = {
      UniversityId: universityId,
      Name: title,
      SignifierType: parseInt(type),
      DropOptions: dropOptions
    };
    axiosBasic({
      url: University.AddSignifier,
      method: 'post',
      data: model
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          Common.showSuccessMessage('Signifier added successfully.');
          setSignifiersList(data);
        } else {
          Common.showErrorMessage('Error occured while adding signifier.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUpdateSignifier = (id, title, type, dropOptions) => {
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    const model = {
      UniversityId: universityId,
      Name: title,
      SignifierType: type,
      SignifierItems: null,
      Id: id,
      DropOptions: dropOptions
    };
    axiosBasic({
      url: University.UpdateSignifier,
      method: 'post',
      data: model
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          setSignifiersList(data);
          Common.showSuccessMessage('Signifier updated successfully.');
        } else {
          Common.showErrorMessage('Error occured while updating signifier.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSignifierList = () => {
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    axiosBasic
      .get(University.GetSignifiers + '?universityId=' + universityId)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          setSignifiersList(data);
        } else {
          Common.showErrorMessage('Error occured while loading signifier.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isOnDeleteSignifier = (data) => {
    setIsDeleteSignifier(true);
    setSelectedSignifier(data);
  };

  const onDeleteSignifier = () => {
    if (selectedSignifier) {
      const universityId = WebStorage.getLocalStore(
        WebStorageNames.UniversityId
      );
      axiosBasic
        .get(
          University.DeleteSignifier +
            '?universityId=' +
            universityId +
            '&signifierId=' +
            selectedSignifier.Id
        )
        .then((response) => {
          const { success, data } = response.data;
          if (success) {
            setSignifiersList(data);
            setIsDeleteSignifier(false);
          } else {
            setIsDeleteSignifier(false);
            Common.showErrorMessage('Error occured while loading signifier.');
          }
        })
        .catch((error) => {
          setIsDeleteSignifier(false);
          console.log(error);
        });
    }
  };

  const onTurnOnOffSignifier = (isOn) => {
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    axiosBasic({
      url:
        University.TurnOnOffSignifier + '?id=' + universityId + '&isOn=' + isOn,
      method: 'post'
    })
      .then(() => {
        WebStorage.setLocalStore(WebStorageNames.CustomSignifier, isOn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onTurnOnOffUniversityLogo = (isOn) => {
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    axiosBasic({
      url:
        University.TurnOnOffUniversityLogo +
        '?id=' +
        universityId +
        '&isOn=' +
        isOn,
      method: 'post'
    })
      .then(() => {
        WebStorage.setLocalStore(WebStorageNames.IsUniversityLogo, isOn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const IsValidDate = (dateObject) => {
    return new Date(dateObject).toString() !== 'Invalid Date';
  };

  const getActiveInactiveStatus = (
    lastActiveDate,
    activeInActiveMarkerDate
  ) => {
    return isActive(lastActiveDate, activeInActiveMarkerDate)
      ? 'Active'
      : 'Inactive';
  };

  const isActive = (lastActiveDate, activeInActiveMarkerDate) => {
    if (!lastActiveDate || !activeInActiveMarkerDate) return false;

    const laDate = new Date(lastActiveDate);
    const amDate = new Date(activeInActiveMarkerDate);

    if (!IsValidDate(laDate) || !IsValidDate(amDate)) return false;
    return laDate > amDate;
  };

  const onConfirmUpdateDomain = () => {
    const { uniData } = location.state;
    let newDomainNameForTest = newDomainName;
    if (newDomainNameForTest.startsWith('.')) {
      //remove that dot for validation
      newDomainNameForTest = newDomainName.substring(1, newDomainName.length);
    }
    if (DomainPatt.test(`@${newDomainNameForTest}`)) {
      setIsDisabled(true);
      domainActions.onUpdateDomain(
        { ...selectedDomain, Name: newDomainName },
        (onSuccess) => {
          if (onSuccess) {
            getDomains({ universityId: uniData.UniversityId });
            closeDomainForm();
          } else {
            setIsDisabled(false);
          }
        }
      );
    } else {
      setErrorTextInvalidDomain('invalid domain name');
    }
  };

  const list = filterList.map((item) => ({
    ...item,
    status: getActiveInactiveStatus(
      item.LastActiveDate,
      ActiveInActiveMarkerDate
    )
  }));
  const { unconfirmed, totalUsers, uniEmail, activeUsers } = getCounters(list);

  return (
    <div className="dashContainer">
      <div className="mainContent">
        <ProfileSignifierDialog
          open={signifierDialog}
          handleClose={closeCampusDialog}
          onSignifierCreate={onCreateButtonPressed}
          onSignifierUpdate={onUpdateSignifier}
          signifierList={signifiersList}
          onDeleteSignifier={isOnDeleteSignifier}
        />
        <DialogBox
          openDialog={isDeleteSignifier}
          onCancel={() => {
            setIsDeleteSignifier(false);
            setSelectedSignifier(null);
          }}
          text={`Are you sure you want to delete this signifier!`}
          heading={`Delete ${selectedSignifier?.Name} signifier?`}
          onConfirm={() => {
            setIsDeleteSignifier(false);
            onDeleteSignifier();
          }}
        />
        <div className="dashView">
          <div style={{ width: '100%' }}>
            <UmiiSearchField
              onValueChange={(value) => {
                searchStudents(value.target.value, unConfirmed);
              }}
              value={searchText}
            />
            <div className="filter">
              <label>
                <input
                  type="checkbox"
                  value={unConfirmed}
                  onChange={(val) => {
                    searchStudents(searchText, val.target.checked);
                  }}
                />
                Unconfirmed
              </label>
            </div>
            <div className="activeInactiveCls">
              Active/Inactive Marker Date
              <div style={{ fontSize: 28 }}>
                {ActiveInActiveMarkerDate
                  ? moment(ActiveInActiveMarkerDate).format(
                      'MM/DD/YYYY HH:mm:ss'
                    )
                  : ''}
              </div>
            </div>
            <Domains
              DomainsList={uniDomains}
              onAddDomainClick={() => setOpenAddDomainForm(true)}
              onDeleteDomainClick={(d) => {
                setOpenAddDomainForm(true);
                setOpenDeleteDomainModal(true);
                setNewDomainName(d.name);
                setSelectedDomain(d);
              }}
              onEditDomainClick={(d) => {
                setIsUpdate(true);
                setOpenAddDomainForm(true);
                setNewDomainName(d.Name);
                setSelectedDomain(d);
              }}
            />

            {/* Custom Signifiers */}
            <div className="date brnadingWrapper">
              <div className="brandingHeader">
                <h2>Custom Profile Signifiers</h2>
                <ToggleSwitch
                  checked={isProfileSignifier}
                  onChange={profileSignifier}
                  onColor="#00cdbe"
                  handleColor="#ffffff"
                  className="toggleSwitch"
                />
              </div>
              {isProfileSignifier && (
                <a
                  onClick={() => {
                    setSignifierDialog(true);
                    getSignifierList();
                  }}
                >
                  {'Manage Signifiers'}
                </a>
              )}
            </div>

            <div className="date brnadingWrapper">
              <div className="brandingHeader">
                <h2>Upload university logo</h2>
                <ToggleSwitch
                  checked={isUniversityLogo}
                  onChange={onUniversityLogoChange}
                  onColor="#00cdbe"
                  handleColor="#ffffff"
                  className="toggleSwitch"
                />
              </div>

              {isUniversityLogo && <UploadLogo logo={universityLogo} />}
            </div>
            <DomainDialogBox
              openDialog={openAddDomainForm}
              name={newDomainName}
              handleChange={(v) => {
                onUniDaminChange(v.target.value.replace(/\s/g, ''));
              }}
              onConfirm={() =>
                openDeleteDomainModal
                  ? onConfirmDeleteDomain()
                  : isUpdate
                  ? onConfirmUpdateDomain()
                  : onConfirmCreateDomain()
              }
              onCancel={() => closeDomainForm()}
              errorText={errorTextInvalidDomain}
              isDeleteDialog={openDeleteDomainModal}
              isUpdate={isUpdate}
              isDisabled={isDisabled}
            />
          </div>

          <div className="gridWrapper">
            <div className="metrics">
              <Totalizer title="Total Users:" counter={totalUsers} />
              <Totalizer title="Uni Email:" counter={uniEmail} />
              <Totalizer title="Unconfirmed:" counter={unconfirmed} />
              <Totalizer title="Active:" counter={activeUsers} />
            </div>
            <DownloadCsv />
            <div className="table">
              {!university.loading ? (
                <StudentGridView
                  getActiveInactiveStatus={getActiveInactiveStatus}
                  isActive={isActive}
                  IsValidDate={IsValidDate}
                />
              ) : (
                <Loader
                  styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItem: 'center',
                    marginTop: 15,
                    marginBottom: 15
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    students: state.students,
    dashboard: state.dashboard,
    loading: state.loading
  }),
  (dispatch) => ({
    studentActions: bindActionCreators(studentAction, dispatch),
    domainActions: bindActionCreators(domainsActions, dispatch),
    clearTitle: () => {
      dispatch(clearHeaderTitle());
    },
    setTitle: (prop) => {
      dispatch(setHeaderTitle(prop));
    }
  })
)(StudentsDashboard);
