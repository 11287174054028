import React from 'react';
import { HeaderView } from '.';

const Main = (props) => {
  const { children } = props;
  return (
    <div className="dashContainer">
      <HeaderView />
      <div>{children}</div>
    </div>
  );
};

export { Main };
