import axios from 'axios';
import { config } from './endPoints';
import { WebStorage, WebStorageNames, messages, Common } from '../utils';
const { ROOT_URL, REST_API } = config;
const { Exception } = messages;

const axiosBasic = axios.create({
  baseURL: ROOT_URL,
  timeout: 60 * 1000,
  headers: { Pragma: 'no-cache' }
});

const issueToken = (refreshToken) =>
  new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'application/json'
    };

    axiosBasic
      .post(
        `${REST_API.Account.RefreshToken}`,
        {
          RefreshToken: refreshToken
        },
        { headers }
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        if (err.response.status === 400 && !err.response.data.success) {
          WebStorage.removeLocalStore(WebStorageNames.AuthInfo);
          WebStorage.removeLocalStore(WebStorageNames.UserInfo);
          Common.showErrorMessage(Exception.sessionExpired);
          window.location.href = '/';
          return;
        }

        reject(err);
      });
  });

export const getAuthToken = () =>
  WebStorage.getLocalStore(WebStorageNames.AuthInfo);

export const configureAxiosDefaults = async () => {
  axiosBasic.defaults.baseURL = ROOT_URL;
  axiosBasic.defaults.headers.post['Content-Type'] = 'application/json';

  axiosBasic.interceptors.request.use(
    (configuration) => {
      const cfg = configuration;
      cfg.headers.Pragma = 'no-cache';
      const url = cfg.url.toLowerCase();
      if (url.endsWith('login') || url.endsWith('forgotpassword')) {
        return cfg;
      }
      const res = getAuthToken();
      if (res !== null) {
        cfg.headers.Authorization = `Bearer ${res.AccessToken}`;
      }
      return Promise.resolve(cfg);
    },
    (err) => {
      Promise.reject(err);
    }
  );
};

export const axiosResponse = async () => {
  axiosBasic.interceptors.response.use(null, async (err) => {
    if (err.config && err.response && err.response.status === 401) {
      const data = getAuthToken();
      if (data !== null) {
        return issueToken(data.RefreshToken).then((res) => {
          const { AccessToken, RefreshToken } = res.data.data;
          const authData = { AccessToken, RefreshToken };
          WebStorage.setLocalStore(WebStorageNames.AuthInfo, authData);
          return Promise.resolve(axiosBasic.request(err.config));
        });
      }
      return Promise.reject(err);
    }
    if (err.message === Exception.networkError) {
      return Promise.reject(err);
    }
    return Promise.reject(err);
  });
};

export { axiosBasic };
