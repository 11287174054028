import PropTypes from 'prop-types';
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';

const UmiiLabel = ({
  className, disabled, error, children,
}) => (
  <FormLabel className={className} disabled={disabled} error={error}>{children}</FormLabel>
);


UmiiLabel.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.string,
};

UmiiLabel.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  children: '',
};
export { UmiiLabel };
