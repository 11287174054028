import React from 'react';
import { Button } from '@material-ui/core';
import { axiosBasic } from '../../../../api/axios';
import { config } from '../../../../api/endPoints';
import { connect } from 'react-redux';

const { University } = config.REST_API;

const DownloadCsv = ({ dashboard: { headerTitle } }) => {
  const onDownloadClick = () => {
    axiosBasic({
      url: University.DownloadStudentsCsv,
      method: 'get',
      params: {
        uniName: headerTitle
      }
    })
      .then((res) => {
        let csvContent = 'data:text/csv;charset=utf-8,' + res.data;
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
          'download',
          `${headerTitle}_Students_${new Date().toISOString().slice(0, 10)}`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Button
      variant="outlined"
      onClick={onDownloadClick}
      className="downloadCsv"
    >
      Download CSV
    </Button>
  );
};

export default connect((state) => ({
  dashboard: state.dashboard
}))(DownloadCsv);
