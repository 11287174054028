import { actionTypes } from "../../../utils";
const { APPLICANT_ACTIONS } = actionTypes;
const {
  SET_UNIVERSITIES,
} = APPLICANT_ACTIONS;

const INITIAL_STATE = {
  universities: [],
};

export default ( state = INITIAL_STATE, action ={}) => {
    if(action.type === SET_UNIVERSITIES)
      return {
        ...state,
        universities: [...action.payload],
      };
      else {
      return state;
    }
};
