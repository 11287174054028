import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import exit from "../../../resources/images/cross_icon.png";

const DialogBox = ({
  openDialog,
  name,
  text,
  onConfirm,
  onCancel,
  heading,
}) => (
  <div>
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      className="dialogBoxCls"
    >
      <button onClick={onCancel} className="dialogCloseBtn">
        <img src={exit} alt="exit" />
      </button>
      <DialogTitle id="customized-dialog-title" onClose={onCancel}>
        {heading || `Confirm ${name}’s uni email address?`}
      </DialogTitle>
      <DialogContentText className="dialogContent">
        <Typography gutterBottom component={"span"} variant={"body2"}>
          {text}
        </Typography>
      </DialogContentText>
      <DialogActions className="dialogButtons">
        <Button onClick={onConfirm} className="confirmBtn">
          Confirm
        </Button>
        <Button onClick={onCancel} className="cancelBtn">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

DialogBox.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  name: PropTypes.string,
  text: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  heading: PropTypes.string,
};
DialogBox.defaultProps = {
  name: "",
  text:
    "Confirming cannot be undone so please make sure this account has been manually vetted.",
  onConfirm: () => {},
  onCancel: () => {},
  heading: null,
};

const DomainDialogBox = ({
  openDialog,
  name,
  onConfirm,
  handleChange,
  onCancel,
  errorText,
  isDeleteDialog,
  isUpdate,
  isDisabled,
}) => {
  return isDeleteDialog ? (
    <DialogBox
      openDialog={openDialog}
      onCancel={onCancel}
      text={`Are you sure you want to delete this domain!`}
      heading={`Delete ${name} domain?`}
      onConfirm={onConfirm}
    />
  ) : (
    <div>
      {openDialog && (
        <Dialog
          onClose={onCancel}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          className="dialogBoxCls"
        >
          <button
            disabled={isDisabled}
            onClick={onCancel}
            className="dialogCloseBtn"
          >
            <img src={exit} alt="exit" />
          </button>
          <DialogTitle id="customized-dialog-title" onClose={onCancel}>
            {`${isUpdate ? "Update" : "Add new"} uni email domain`}
          </DialogTitle>
          <DialogContentText className="dialogContent">
            <TextField
              id="standard-name"
              label="Name"
              value={name}
              onChange={handleChange}
              margin="normal"
              error={errorText ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">@</InputAdornment>
                ),
              }}
              helperText={errorText}
            />
          </DialogContentText>
          <DialogActions className="dialogButtons">
            <Button
              disabled={isDisabled}
              onClick={onConfirm}
              className="confirmBtn"
            >
              {isUpdate ? "Save" : "Confirm"}
            </Button>
            <Button
              disabled={isDisabled}
              onClick={onCancel}
              className="cancelBtn"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

DomainDialogBox.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onConfirm: PropTypes.func,
  handleChange: PropTypes.func,
  onCancel: PropTypes.func,
  errorText: PropTypes.string,
  isUpdate: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DomainDialogBox.defaultProps = {
  name: "",
  onConfirm: () => {},
  handleChange: () => {},
  onCancel: () => {},
  errorText: "",
  isUpdate: false,
  isDisabled: false,
};

export { DialogBox, DomainDialogBox };
