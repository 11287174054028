import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import { invertDirection, Common } from '../../../utils';
import { rowItem } from './rowItem';

const GridView = ({ dataList, headers, onRowItemClick, renderRowItem }) => {
  const [columnToSort, setColumnToSort] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleSort = (columnName) => {
    setColumnToSort(columnName);
    setSortDirection(
      columnToSort === columnName ? invertDirection[sortDirection] : 'asc'
    );
  };

  const checkArrowDirection = () => {
    return sortDirection === 'asc' ? <UpArrow /> : <DownArrow />;
  };

  let list = [];
  if (columnToSort === 'LastActiveDate') {
    const temp = [...dataList];
    temp.sort((a, b) => {
      if (sortDirection === 'asc') {
        return (
          new Date(a.LastActiveDate) - new Date(b.LastActiveDate)
          //  ||
          // b.status.localeCompare(a.status)
        );
      } else {
        return (
          new Date(b.LastActiveDate) - new Date(a.LastActiveDate)
          //  ||
          // a.status.localeCompare(b.status)
        );
      }
    });
    list = temp;
  } else if (columnToSort === 'status') {
    const temp = [...dataList];
    temp.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a.status.localeCompare(b.status);
        //  ||
        // new Date(b.LastActiveDate) - new Date(a.LastActiveDate)
      } else {
        return b.status.localeCompare(a.status);
        // ||
        // new Date(a.LastActiveDate) - new Date(b.LastActiveDate)
      }
    });
    list = temp;
  } else {
    list = orderBy(dataList, columnToSort, sortDirection);
  }

  return (
    <Table className="uniTable">
      <TableHead className="tableHead">
        <TableRow className="tableRow">
          {headers.map((header) => (
            <TableCell
              key={header.id}
              align={header.numeric ? 'left' : 'right'}
            >
              <div onClick={() => handleSort(header.prop)}>
                <span>{header.name}</span>
                {columnToSort === header.prop && checkArrowDirection()}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className="tableBody">
        {list.map((data, i) =>
          !Common.isFunction(renderRowItem)
            ? rowItem(data, i, onRowItemClick)
            : renderRowItem(data, i, onRowItemClick)
        )}
      </TableBody>
    </Table>
  );
};

GridView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataList: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.array.isRequired
};

export { GridView };
